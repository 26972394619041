<template>
  <div class="flex min-h-screen flex-col">
    <Header />
    <main class="flex flex-1 flex-col bg-bg-light pt-[72px]">
      <div class="relative flex flex-1 flex-col md:flex-row-reverse">
        <div class="absolute h-full bg-cover md:static md:h-auto md:w-7/12 md:bg-[url('/home.webp')]"></div>
        <div class="relative flex flex-1 flex-col md:w-5/12">
          <slot />
        </div>
      </div>
    </main>
    <UiSnackBanner />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
